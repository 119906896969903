import { configureStore } from '@reduxjs/toolkit'

import loadReducer from './loadSlice'
import signinReducer from './signinSlice'

const store = configureStore({
	reducer: {
		load: loadReducer,
		signin: signinReducer
	}
})

export default store