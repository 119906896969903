import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import store from './store.js'

export const authenticate = createAsyncThunk('load/whatever', async () => {
  return 'ok'
})

const loadSlice = createSlice({
	name: 'load',
	initialState: {desktop: true},
	reducers: {
		empty(state, action) {
			var w = window.innerWidth;
			var h = window.innerHeight;
			if (w > h) {
				state.desktop = true
				state.width = w*0.333333
			} else {
				state.desktop = false
				state.width = w
			}
			console.log(state.width)
		},
	},
	extraReducers: {
		    [authenticate.fulfilled]: (state, action) => {
		    },
		    [authenticate.rejected]: (state, action) => {
		    },
	},
})

export const { empty } = loadSlice.actions

export default loadSlice.reducer
