import { useState } from 'react';

import store from './store.js'

/*
	This is a universal textFieldController.

	Example usage:
	const [usernameInput, handleUsernameChange] = textFieldController(setUsername)
	<TextField className={classes.fieldStyle} label="Username" variant="outlined" onChange={handleUsernameChange} />
*/
export default function useTextFieldController(reducer) {
  const [input, setInput] = useState({})

  const handleInputChange = (e) => {
    store.dispatch(reducer(e.currentTarget.value))
    setInput({...input, [e.currentTarget.name]: e.currentTarget.value})
  }

  return [input, handleInputChange]
}