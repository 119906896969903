import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import Cookies from 'js-cookie'

import store from './store.js'

import api from './api'

export const signin = createAsyncThunk('signin/signin', async () => {
  const email = store.getState()
  console.log(email)
  const response = await api.email(email)
  return response
})

export const submitEmail = createAsyncThunk('signin/submitEmail', async () => {
	const email = store.getState().signin.Email
  	const response = await api.email(email)
  	return response
})

export const submitPassword = createAsyncThunk('signin/submitPassword', async () => {
	const email = store.getState().signin.Email
	const password = store.getState().signin.password
	console.log('here')
	const response = await api.validatePassword(email, password)
	return response
})

export const submitConfirmPassword = createAsyncThunk('signin/submitConfirmPassword', async () => {
	const email = store.getState().signin.Email
	const password = store.getState().signin.password
  	const response = await api.setPassword(email, password)
  	return response
})

const signinSlice = createSlice({
	name: 'signin',
	initialState: {emailSet: false, loggedIn: false},
	reducers: {
		startUp(state, action) {
			// Bootstraps authentication status
			const cookie = Cookies.get('token')
			if (typeof cookie === 'undefined' || cookie === '') {
			} else {
				state.token = Cookies.get('token')
				state.loggedIn = true
			}
		},
		setEmail(state, action) {
			state.Email = action.payload.toLowerCase()
		},
		setEmailWrong(state, action) {
			state.emailWrong = true
			console.log('email wrong')
		},
		setPassword(state, action) {
			state.password = action.payload
			console.log(action.payload)
		},
		setConfirmPassword(state, action) {
			state.confirmPassword = action.payload
		},
		signOut(state, action) {
			Cookies.set('token', '')
			state.token = ''
			state.loggedIn = false
			state.emailSet = false
			console.log('here')
		}
	},
	extraReducers: {
		    [submitEmail.fulfilled]: (state, action) => {
		    	state.emailPass = true
		    	state.emailSet = true
		    	state.existingEmail = action.payload.found
		    	console.log(state.existingEmail)
		    },
		    [submitEmail.rejected]: (state, action) => {
		    	state.emailWrong = true
		    	console.log('there')
		    },
		    [submitPassword.fulfilled]: (state, action) => {
		    	if (action.payload != 'no') {
		    		state.loggedIn = true
		    		state.token = action.payload
		    		Cookies.set('token', action.payload)
		    	}
		    },
		    [submitConfirmPassword.fulfilled]: (state, action) => {
		    	state.loggedIn = true
		    	state.token = action.payload
		    	Cookies.set('token',action.payload)
		    	console.log(action.payload)
		    }
	},
})

export const { startUp, setEmail, setEmailWrong, setPassword, setConfirmPassword, signOut } = signinSlice.actions

export default signinSlice.reducer
