import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './store';

import { useRoutes, useRedirect, navigate } from 'hookrouter';
import { useSelector } from 'react-redux'

import routes from './routes'
import { empty } from './loadSlice'

import './App.css';

import { startUp } from './signinSlice'

const MyApp = () => {
	const loggedIn = useSelector((state) => state.signin.loggedIn)
	if(window.location.pathname=='/profile' && !loggedIn) {
		navigate('/signin')
	}
    //useRedirect('/', '/tasks');
    store.dispatch(startUp())

    if(window.location.pathname=='/signin' && loggedIn) {
      navigate('/profile')
    }

    const routeResult = useRoutes(routes);
    
    return routeResult || <NotFoundPage />;
}

function NotFoundPage() {
  return <div>404 lol</div>
}

ReactDOM.render(
    <Provider store={store}>
      <MyApp />
    </Provider>,
  document.getElementById('root')
)
