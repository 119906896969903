import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import React, {useRef} from 'react';
import {useSelector} from 'react-redux'

const useStyles = makeStyles({
  rootdesktop: {
    width: '33.333333%',
    position: 'fixed',
    bottom: 0,
    background: 'blue',
  },
  rootmobile: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: 'blue',
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const desktop = useSelector((state) => state.load.desktop)
  const displayClass = desktop ? classes.rootdesktop : classes.rootmobile;

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={displayClass}
    >
      <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
      <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
      <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
    </BottomNavigation>
  );
}