import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Post from './Post.js'



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  spacer: {
  	marginTop: 20,
  }
}));

export default function PostPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box >
        <Post />
        <div className={classes.spacer} />
      </Box>
    </div>
  );
}