// core components/views for Admin layout
import SomeRoute from "./SomeRoute.js";
import Frame from './Frame.js';

import SigninPage from './SigninPage'

import PostList from './PostList'
import Post from './Post'
import PostPage from './PostPage'
import ProfileGridContainer from './ProfileGridContainer'

import NewDesign from './NewDesign'
import ProfilePage from './ProfilePage'
import NotFoundPage from './NotFoundPage'

const routes = {
    '/': () => <NewDesign />,
    '/home': () => <NewDesign />,
    '/profile': () => <ProfilePage />,
    '/404': () => <NotFoundPage />,
    '/signin': () => <SigninPage />,
    '/feed': () => <Frame page={<PostList />} />,
    '/explore': () => <Frame page={<PostList />} />,
    '/post/:id': ({id}) => <Frame page={<PostPage id={id} />} />,
};

export default routes