import Grid from '@material-ui/core/Grid';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import SimpleBottomNavigation from './BottomNavigation.js'
import Post from './Post.js'
import PostList from './PostList.js'
import ProfileGridContainer from './ProfileGridContainer.js'

import SignInButton from './SignInButton.js'

const useStyles = makeStyles((theme) => ({
  container: {
  	height: '100%',
  },
  side: {
  	background: '#282c34',
  },
  root: {
    background: 'white',
    maxHeight: '100%',
    overflow: 'auto',
  },
}));

export default function Frame(props) {
	const classes = useStyles();
	const desktop = useSelector((state) => state.load.desktop)

	console.log(desktop)

	if (desktop) {
		return (
		<Grid container className={classes.container}>
			<Side />
      		<Item width={4} page={props.page} />
      		<Side />
      	</Grid>
      	)
	} else {
		return <Grid container className={classes.container}>
		<Item width={12} page={props.page} />
		</Grid>
	}
}

function Side() {
	const classes = useStyles();
	return <Grid className={classes.side} item xs={4}>
      		</Grid>
}

function Item(props) {
	const classes = useStyles();
	return <Grid className={classes.root} item xs={props.width}>

				{props.page}
				<SignInButton />
      			<SimpleBottomNavigation />
      		</Grid>
}

